






import {
  watch,
  computed,
  defineComponent,
  SetupContext,
} from '@vue/composition-api';

// Components
import Dialog from '../../atoms/Dialog.vue';
import FormSharedCode from '../../molecules/FormSharedCode';

export default defineComponent({
  name: 'DialogSharedCode',
  components: {
    Dialog,
    FormSharedCode,
  },
  props: {
    sharedCode: {
      type: String,
      required: false,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }: SetupContext) {
    const methods = {
      on: {
        close: () => emit('close', { shared: true, code: props.sharedCode }),
      },
    };
    return {
      ...methods,
    };
  },
});
