






















































import * as clipboard from 'clipboard-polyfill/text';
import {
  toRefs,
  watch,
  computed,
  reactive,
  defineComponent,
  SetupContext,
} from '@vue/composition-api';

export default defineComponent({
  name: 'FormSharedCode',
  props: {
    sharedCode: {
      type: String,
      required: true,
    },
  },
  setup(props, { root, emit }: SetupContext) {
    const state = reactive({
      password: '',
      isLoading: false,
      requirePassword: false,
    });

    const calculated = {
      existPassword: computed(
        () => root.$store.getters['events/existPassword'],
      ),
    };

    const methods = {
      on: {
        ingress: () => {
          state.isLoading = true;
          root.$store.dispatch('events/joinPublicDialog', {
            sharedCode: props.sharedCode,
            password: state.password,
          });
        },
        copy: async () => {
          await clipboard.writeText(props.sharedCode);
          // @ts-ignore
          root.$vs.notification({
            sticky: true,
            color: 'dark',
            position: 'top-center',
            title: 'Texto copiado',
          });
        },
      },
    };

    watch(calculated.existPassword, existPassword => {
      state.requirePassword = existPassword;
    });

    return {
      ...methods,
      ...calculated,
      ...toRefs(state),
    };
  },
});
